import { graphql, Link } from 'gatsby'
import * as React from 'react'
import Layout from '../../components/layout'
import Seo from '../../components/seo'

const BlogPage = ({ data, location }) => {
  return (
    <Layout location={location}>
      <Seo title="Blog" />

      <h1 className="max-w-3xl mx-auto text-4xl text-center sm:mb-4 sm:text-5xl">
        Your NEWA Blog
      </h1>
      <div className="px-3 pb-4 sm:pt-2">
        {data.allMdx.nodes.map((blog) => (
          <div
            key={blog.slug}
            className="h-full max-w-3xl p-4 mx-auto my-16 list-none bg-white border border-l-8 rounded-lg shadow-md hover:shadow-lg border-primary-700"
          >
            <Link
              key={blog.slug}
              to={`/blog/${blog.slug}`}
              className="no-underline"
            >
              <p className="text-xl font-semibold text-gray-900 md:text-2xl">
                {blog.frontmatter.title}
              </p>
              <div className="mt-2 text-base text-gray-500">
                {blog.frontmatter.description}
              </div>
              <div className="mt-4 font-semibold text-gray-900">
                {blog.frontmatter.author}
              </div>
              <div className="mt-1 text-sm text-gray-500">
                {blog.frontmatter.date}
              </div>
            </Link>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        slug
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          nextPost
          prevPost
          slug
          tags
          title
          author
          description
        }
      }
    }
  }
`

export default BlogPage
